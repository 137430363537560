import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (
  <Layout title="About Noteimals">
      <h2>About Noteimals</h2>
      <div className="clear-block">
          <div id="node-49" className="node">
            <div className="content clear-block">
              <p>My name is Glenna Cook.  I have successfully taught music for 46 years, and I truly enjoy working with children. I am thrilled to see my students succeed.  However, like many teachers, I have had my share of difficulties helping my students learn to note read.  And if they could not read notes, learning correct timing was just as difficult.  To help with this I would tell stories about the notes: middle C looks like a cat with whiskers and lives in a box, D became a dog who likes to dig out from under treble clef, etc.  I soon learned that creating music where notes became caricatures of animals made the learning easier and more fun. Thus the Animal Notes were born.  One of my music “Moms” convinced me to put the many little Note Animal songs I had been writing into books.  In 1995, with the help of my niece, Ariane, and my Russian son, Stas, this became a reality.  Stas then put them on the web in 1996.  </p>
              <p>With each passing year, more books have been added, and our company has grown.  It is amazing to watch the children have fun learning music and succeed beyond expectation.  To watch the love of music grow within an individual is very rewarding.  The comments from so many of our customers are very encouraging. </p>
              <p>Noteimals™ is dedicated to bringing you the best possible method of beginning music education. It is a system that is used by beginning to experienced teachers to start their new students in a fun and successful way. The method is great in the classroom situation as it keeps the students’ attention while the teacher works with others in the class.  Yet this method can be and is being successfully used by parents and grandparents to start their young children on the path to true music appreciation.  Some of these individuals have never had any musical training and are enjoying learning with their children.  What a wonderful experience.</p>
              <p>We love your feedback: <Link to="/contact">Contact Us</Link>.</p>
              <p>Connect with <Link to="/connect">Teachers using the Noteimals method</Link>.</p>
            </div>

            <div className="clear-block">
              <div className="meta">
              </div>

            </div>

          </div>
        </div>
  </Layout>
)
